import * as React from 'react';

import { IoHomeOutline } from 'react-icons/io5';
import FunctionalButton from '../components/common/functionalButton/functionalButton';
import styled from 'styled-components';

const UnstyledLink = styled.a`
  color: black;
`;

const NotFoundPage = () => {
  return (
    <main
      style={{
        minHeight: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <div className={'head-text'}>Missing mail...</div>
      <UnstyledLink href={'/'}>
        <FunctionalButton>
          <IoHomeOutline size={80} />
        </FunctionalButton>
      </UnstyledLink>
    </main>
  );
};

export default NotFoundPage;
